/* eslint-disable prefer-arrow-callback */
/* eslint-disable jquery/no-wrap */
/* eslint-disable jquery/no-each */
/* eslint-disable jquery/no-text */
/* eslint-disable jquery/no-trim */
/* eslint-disable jquery/no-html */
/**
 * This is the main entry point for the brand.
 */

import $ from 'jquery';
import Glide from '@glidejs/glide';
import '~base'; // call the main module\

const addReadMoreLink = function () {
  const mediaQueryMd = window.matchMedia('(min-width: 768px)');
  const mediaQueryXl = window.matchMedia('(min-width: 1280px)');
  let maxLength;

  if (mediaQueryXl.matches) {
    maxLength = 350;
  } else if (mediaQueryMd.matches) {
    maxLength = 136;
  } else {
    maxLength = 126;
  }

  $('.show-read-more').each(function () {
    const myStr = $(this).text();
    // eslint-disable-next-line prefer-destructuring
    const fontColor = this.dataset.fontColor;
    const displayShowMoreButton = this.dataset.showReadMoreButton;
    if (displayShowMoreButton === 'true') {
      if ($.trim(myStr).length > maxLength) {
        const newStr = myStr.substring(0, maxLength);
        const removedStr = myStr.substring(maxLength, $.trim(myStr).length);
        $(this).empty().html(newStr);
        $(this).append(`${'<span class="truncate-text">...</span> '
          + '<a href="javascript:void(0);" class="read-more B2-bold" style="color: '}${fontColor}">Read more</a>`);
        $(this).append(`<span class="more-text">${removedStr}</span>`);
      }
    }
  });
  $('.read-more').click(function () {
    $(this).siblings('.more-text').contents().unwrap();
    $(this).siblings('.truncate-text').remove();
    $(this).remove();
  });
};

const PDPCarouselInit = function () {
  if ($('.recommendation-carousel').length) {
    const sliders = document.querySelectorAll('.recommendation-carousel');
    let customPerView = 6;
    let customPerViewTab = 4;
    for (let i = 0; i < sliders.length; i++) {
      if (sliders[i] && sliders[i].parentNode && sliders[i].parentNode.classList
        && sliders[i].parentNode.classList.contains('product-set__product-carousel')) {
        customPerView = 3;
        customPerViewTab = 2;
      }
      new Glide(sliders[i], {
        type: 'carousel',
        startAt: 0,
        focusAt: 0,
        perView: customPerView,
        gap: 20,
        peek: { before: 0, after: 90 },
        breakpoints: {
          992: {
            perView: customPerViewTab,
          },
          768: {
            gap: 16,
          },
          540: {
            perView: 1,
          },
        },
      }).mount();
    }
  }
};

$(document).ready(() => {
  addReadMoreLink();

  // eslint-disable-next-line no-shadow, no-unused-vars
  const FixBoundPeek = function (Glide, Components) {
    return {
      modify(translate) {
        let { isBound } = Components.Run.isBound;
        if (typeof isBound !== 'function') {
          isBound = function () {
            return Glide.isType('slider') && Glide.settings.focusAt !== 'center' && Glide.settings.bound;
          };
        }

        if (isBound() && Components.Run.isEnd()) {
          const peek = Components.Peek.value;
          if (typeof peek === 'object' && peek.after) {
            return translate - peek.after;
          }
          return translate - peek;
        }
        return translate;
      },
    };
  };

  if ($('.carousel-bg').length) {
    new Glide('.carousel-bg', {
      type: 'carousel',
      perView: 4,
      gap: 16,
      breakpoints: {
        1400: {
          perView: 3,
          focusAt: 'center',
        },
        540: {
          perView: 2,
          focusAt: 'center',
        },
      },
    }).mutate([FixBoundPeek]).mount();
  }

  if ($('.hero-carousel').length) {
    new Glide('.hero-carousel', {
      type: 'carousel',
      startAt: 0,
      focusAt: 0,
      perView: 3,
      gap: 40,
      peek: { before: 0, after: 80 },
      breakpoints: {
        768: {
          perView: 2,
        },
        375: {
          perView: 1,
          peek: { before: 0, after: 135 },
        },
      },
    }).mutate([FixBoundPeek]).mount();
  }

  if ($('.category-carousel').length) {
    new Glide('.category-carousel', {
      type: 'carousel',
      startAt: 0,
      focusAt: 'center',
      perView: 5,
      gap: 16,
      peek: { before: 0, after: 50 },
      breakpoints: {
        1600: {
          perView: 4,
        },
        1280: {
          perView: 3,
        },
        768: {
          perView: 2,
        },
        375: {
          perView: 1,
          peek: { before: 0, after: 80 },
        },
      },
    }).mutate([FixBoundPeek]).mount();
  }

  PDPCarouselInit();
  mapFilterRemovalButton();
});

document.addEventListener('product:afterAttributeSelect', function () { // eslint-disable-line prefer-arrow-callback
  PDPCarouselInit();
});

function mapFilterRemovalButton() {
  $('body').on('click', '.search-results-container .filters__filter__applied', function (e) {
    if ($('.filters-container').length > 0) {
      const selectedFilter = e.currentTarget;
      selectedFilter.querySelector('.filters__filter__applied__remove').click();
    }
  });
}
